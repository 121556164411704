import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import useDarkMode from "use-dark-mode"
import ReactPlayer from "react-player";
import "../styles/index.sass";

const TemplateWrapper = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [play, setPlay] = useState(false)
  const darkMode = useDarkMode(false);

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            introTextNode {
              childMarkdownRemark {
                html
              }
            }
            copyright
            externalLink
          }
          allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
            edges {
              node {
                profileType
                url
              }
            }
          }
        }
      `}
      render={data => (
        <>
        <div 
          className={`container ${showMenu ? "is-open" : ""}`}
        >
          <HelmetDatoCms
            favicon={data.datoCmsSite.faviconMetaTags}
            seo={data.datoCmsHome.seoMetaTags}
          />
          <div className="container__sidebar">
            <div className="sidebar">
              <h6 className="sidebar__title">
                <a onClick={() => {
                  setShowMenu(false);
                  navigate('/')
                }}>{data.datoCmsSite.globalSeo.siteName}</a>
              </h6>
              
              <ul className="sidebar__menu">
                {/* <li>
                  <a onClick={() => {
                    setShowMenu(false);
                    navigate('/')
                  }}>Home</a>
                </li> */}
                <li>
                  <a onClick={() => {
                    setShowMenu(false);
                    setPlay(!play)
                  }}>{!play ? 'Play' : 'Pause'}</a>
                </li>
                <li>
                  <a onClick={() => {
                    setShowMenu(false);
                    navigate('/bio')
                  }}>Bio</a>
                </li>
              </ul>

              <li className="dark__toggle">
                    <label>
                      <input
                        type="checkbox"
                        onChange={darkMode.toggle}
                        checked={darkMode.value}
                      />{' '}
                      🌓
                    </label>
                </li>
            </div>
          </div>
          <div className="container__body">
            <div className="container__mobile-header">
              <div className="mobile-header">
                <div className="mobile-header__menu">
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setShowMenu(!showMenu);
                    }}
                  />
                </div>
                <div className="mobile-header__logo">
                  <a onClick={() => {
                    setShowMenu(false);
                    navigate('/')
                  }}>{data.datoCmsSite.globalSeo.siteName}</a>
                </div>
              </div>
            </div>
            {children}
          </div>
          <ReactPlayer
            className="react-player"
            playing={play}
            url={data.datoCmsHome.externalLink}
          />
        </div>
        </>
      )}
    />
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.object
};

export default TemplateWrapper;
